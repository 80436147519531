import styled from 'styled-components'
import {colors} from '../config/colors.js'

export const StyledFeaturedWorkPageDescription = styled.p({
  textAlign: 'center',
})

export const StyledFeaturedWorksSection = styled.section({
  ul: {
    padding: '0 2rem',
    margin: '0 auto',
    listStyleType: 'none',
  },

  '@media screen and (min-width: 768px)': {
      ul: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxWidth: '100rem',
      },

      li: {
        width: '48%',
      },
  },
})

export const StyledFeaturedWork = styled.div(props => ({
  figure: {
    height: '40rem',
    maxWidth: '40rem',
    margin: '4rem auto',
    backgroundImage: `url(https:${props.backgroundImageURL})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },

  figcaption: {
    padding: '0.5rem',
    backgroundColor: colors['black-#000-with-shadow'],
    color: colors['white-#ddd'],
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    textAlign: 'center',
  },

  h2: {
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
}))
